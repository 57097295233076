import React from 'react';
import styled from 'styled-components';
import ListHeader from './list-header';
import ListBody from './list-body';
import loadingAni from '../../resources/loading.gif';

const DataListContainer = styled.div`
  padding-top: 10px;
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 480px;

  & img {
    width: 60px;
  }
`;

export default (props) => {
  // console.log(props.listStatus);
  return (
    <DataListContainer>
      <ListHeader cfgs={props.cfgs} showCheckbox={props.showCheckbox} buttonAreaWidth={props.buttonAreaWidth} />
      {props.listStatus==="loading" ? <LoadingContainer><img alt="Loading..." src={loadingAni} /></LoadingContainer> : <ListBody {...props} />}
    </DataListContainer>
  );
}