import axios from 'axios';
import store from '../store';

export async function getMainMenus() {
	const res = await axios.get('/menus');
  //console.log(res.data);
  const mainMenus = res.data.objs;

  const menus = mainMenus.slice();
  const promises = menus.map( async (menu,i) => {

    try {
      const { conf } = await import('../configurations' + menu.menuLink + '-dashboard-conf');
      menus[i]['conf'] = conf;
    } catch (error) {
      //console.log(error);
      menus[i]['conf'] = null;
    }

    const subMenus = menu.subMenus.slice();
    const promisesSub = menu.subMenus.map( async (subMenu,j) => {
      try {
        const { conf } = await import('../configurations' + subMenu.menuLink + '-list-conf');
        subMenus[j]['conf'] = conf;
      } catch (error) {
        //console.log(error);
        subMenus[j]['conf'] = null;
      }
      return subMenus[j];
    });

    await Promise.all(promisesSub).then( subMenus => {
      menus[i].subMenus = subMenus;
    });

    return menus[i];
  });
  Promise.all(promises).then( mainMenus => {
    store.dispatch({ type: 'get_mainMenus', mainMenus });
  });

  //store.dispatch({ type: 'get_mainMenus', mainMenus });
  return res;
}

// export function getSubMenus(subMenus) {
//   const storeSubMenus = store.getState().menus.subMenus;
//   if((storeSubMenus.length>0 && storeSubMenus !== subMenus) || (storeSubMenus.length===0 && subMenus.length>0) || (storeSubMenus.length>0 && subMenus.length===0)) {
//     store.dispatch({type:'get_subMenus', subMenus});
//   } 
// }