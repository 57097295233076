import React from 'react';
import styled from 'styled-components';
import { theme } from '../../configurations/general-conf';
import SubMenus from '../views/sub-menus';

const SideBarContainer = styled.aside`
  width: 13.96875%;
  height: 100%;
  position: absolute;
  float: left;
  padding-top: 94px;
  top: 0;
  background: ${theme.mainBgColor};
`;

const LeftBank1 = styled.div`
  height: 90px;
`;

const LeftBank2 = styled.div`
  height: 25px;
	background-color: white;
`;

const TipsContainer = styled.div`
  height: 210px;
  background-color: ${theme.mainDeepColor};
  color: white;
  position:absolute;
  bottom: 0;
  & * {
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 10px;
  }
  & svg {
    margin: 0 5px 0 0;
    vertical-align: middle;
    display: inline-block;
  }
`;

const TipsTitle = styled.p`
  font-size: 14px;
`;

export default function(props) {
  let subMenus = [];
  const topLevelPath = '/' + window.location.pathname.split('/')[1];
  props.menus.map(mainMenu => {
    if(topLevelPath===mainMenu.menuLink){
    	subMenus = mainMenu.subMenus;
    }
    return null;
  });

  return (
    <SideBarContainer>
			<LeftBank1></LeftBank1>
			<LeftBank2></LeftBank2>
			<SubMenus subMenus={subMenus} />
			<TipsContainer> 
				<TipsTitle> <svg className="icon-tipsbubble"><use xlinkHref="#tipsbubble" /></svg> Tips </TipsTitle>
				<hr />
				<p> There are some tips here for how to use the system. Currently under contruction, more to come. </p>
			</TipsContainer>
		</SideBarContainer>
  );
}