import React from 'react';
import { Route, BrowserRouter } from 'react-router-dom';
import LoginContainer from './components/containers/login-container';
import MainLayout from './components/layouts/main-layout';
import * as restful from './api/restful-api';

if(restful.getCookie('auth_token').length<60){
  //console.log(window.location.href.indexOf('/login'));
  if(window.location.pathname!=='/'){
    window.location.href='/';
  }
}

const FrameLayout = restful.getCookie('auth_token').length<60 ? LoginContainer : MainLayout;

export default (
  <BrowserRouter>
    <Route path="/" component={FrameLayout} />
  </BrowserRouter>
);