import React from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { theme } from '../../configurations/general-conf';
import DateBox from './date-box';
import moment from 'moment';
import store from '../../store';

const SecondSubListContainer = styled.div`
    width: 80%;
    padding-left: 1%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 2%;
    row-gap: 10px;
`;

const SecIconContainer = styled.span`
  display: inline-block;
  padding: 10px;

  & button {
    border: none;
    padding: 0;
    background: none;
    cursor: pointer;
  }
`;

const SecFormContainer = styled.div`
  width: 48%;
  padding: 10px;
  border: 1px dashed ${(props)=> props.valid ? theme.mainColor: theme.failureColor};
  position: relative;
`;

const InputsContainer = styled.div`
  padding-bottom: 10px;

  &:last-child {
    padding-bottom: 0;
  }
`;

const Input = styled.input`
  font-size: 14px;
  width: 100%;
  height: 38px;
  box-sizing: border-box;
  border: 1px solid ${theme.mainBgColor};
  padding: 0 0 0 10px; 
  font-family: ${theme.lightFont};

  background-color: ${(props)=> props.readOnly ? theme.mainBgColorLight: theme.mainBrightestColor};
`;

const TextArea = styled.textarea`
  font-size: 14px;
  width: 100%;
  padding: 10px;
  border: 1px solid ${theme.mainBgColor};
  font-family: ${theme.lightFont};

  background-color: ${(props)=> props.readOnly ? theme.mainBgColorLight: theme.mainBrightestColor};
`;

const BottomButtonsContainer = styled.div`
  
`;

const BottomButton = styled.button`
  height: 38px;
  width: 80px;
  margin-right: 10px;
  border: none;
  padding: 0;
  font-size: 12px;
  color: #fff;
  background-color: ${(props) =>
    props.submitChange
      ? theme.mainLighterColor
      : props.cancelChange
      ? theme.failureColor
      : theme.mainDarkColor};

  &:hover {
    background-color: ${(props) =>
      props.submitChange
        ? theme.mainLightColor
        : props.cancelChange
        ? theme.failureLightColor
        : theme.mainDarkColorHover};
  }
`;

const SubListDateContainer = styled.div`
  margin-left: 12px;
  font-size: 14px;
  line-height: 30px;
  color: ${theme.mainDarkColor};
  font-family: ${theme.lightFont};
`;

const BtnContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;

  & button {
    border: none;
    padding: 0;
    background: none;
    cursor: pointer;
  }
`;

export default class extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      showCreateIcon: true,
      createFormValid: true,
      dataList: [],
    }
  }

  loadSecSubList = async () => {
    const { endPoint, subKey, defaultQuery } = this.props.conf;
    const { mainKey } = this.props;
    const res = await axios.get(endPoint + '?qryStr=' + mainKey + '&' + subKey + '=' + this.props.subKey + defaultQuery);
    if(res.data?.objs?.length >= 0){
      // const showCreateIcon = control?.values.includes(res.data.objs[0][control?.key]) ? true : false;
      this.setState({dataList: res.data.objs});
    }
  }

  componentDidMount () {
    this.loadSecSubList();
  }
  
  createItem = async () => {
    const { fields, mainKey, subKey, endPoint, defaultCreateData } = this.props.conf;
    const data = { ...defaultCreateData, isValid: true };
    data[mainKey] = this.props.mainKey;
    data[subKey] = this.props.subKey;
    fields.map( field => {
      switch(field.type) {
        case 'number':
          data[field.name] = this.refs[field.name].value ? parseInt(this.refs[field.name].value) : null;
          break;
        case 'date':
          data[field.name] = this.refs[field.name].state.value;
          break;
        case 'multiText':
          data[field.name] = this.refs[field.name].value ? this.refs[field.name].value : null;
          break;
        default:
      }
      field.mandatory && !data[field.name] && (data.isValid = false);
      return field.mandatory && (!data[field.name] ? this.setState({createFormValid: false}) : this.setState({createFormValid: true}));
    });
    if(data.isValid){
      this.refs['saveChangeButton'].disabled = true;
      const res = await axios.post(endPoint + '/new', data);
      if (res.data.result === 1) {
        this.loadSecSubList();
        this.setState({showCreateIcon: true});
        store.dispatch({ type: 'add_single_statsItems' + this.props.subKey, data: res.data.objs[0]});
      } else {
        window.alert(res.data.msg);
        this.refs['saveChangeButton'].disabled = false;
      }
    }
  }

  delItem = async (uuid) => {
    const { endPoint, uuidKey } = this.props.conf;
    if (!window.confirm('Are you sure to delete this item?')) return;
    const res = await axios.delete(endPoint + '/del/' + uuid);
    if (res.data.result === 1) {
      this.loadSecSubList();
      store.dispatch({ type: 'del_single_statsItems' + this.props.subKey, listItemId: uuid, filterKey: uuidKey });
    } else {
      window.alert(res.data.msg);
    }
  }

  render () {
    const { addIcon, fields, control } = this.props.conf;
    // console.log(this.props.subControl);
    return (
      <SecondSubListContainer>
        {this.state.dataList.map( (item, i) => {
          return (
            <SecFormContainer valid key={i}>
              {fields.map( (field, j) => {
                switch(field.type) {
                  case 'date':
                    return (
                      <SubListDateContainer key={field.name+j}><span>{field.conf.placeholder}: {moment(item[field.name]).format('DD/MM/YYYY')}</span></SubListDateContainer>
                    );
                  case 'multiText':
                    return (
                      <InputsContainer key={field.name+j}>
                        <TextArea value={field.placeholder + ": " + item[field.name]} readOnly rows={field.rows} />
                      </InputsContainer>
                    );
                  case 'number':
                    return (
                      <InputsContainer key={field.name+j}>
                        <Input type="text" value={field.placeholder + ": " + item[field.name]} readOnly />
                      </InputsContainer>
                    );
                    case 'deletebtn':
                      return (control?.values.includes(item[control?.key]) ? <BtnContainer key={field.name+j}><button title='Delete' onClick={() => this.delItem(item[field.name])}><svg className="icon-deletebin"><use xlinkHref="#deletebin" /></svg></button></BtnContainer> : null);
                  default:
                    return null;
                }
              })}
            </SecFormContainer>
          );
        })}
        { this.state.showCreateIcon && control?.values.includes(this.props.subControl) && <SecIconContainer><button title="New Production Plan" onClick={()=>this.setState({showCreateIcon: false})}>
          <svg className={"icon-"+addIcon}><use xlinkHref={"#"+addIcon} /></svg>
        </button></SecIconContainer>}
        { !this.state.showCreateIcon && <SecFormContainer valid={this.state.createFormValid}>
          {fields.map( (field, i) => {
            switch(field.type) {
              case 'number':
                return (
                  <InputsContainer key={field.name+i}>
                    <Input type={field.type} ref={field.name} placeholder={field.placeholder} max={ field.max ? field.max : null }/>
                  </InputsContainer>
                );
              case 'date':
                return (
                  <InputsContainer key={field.name+i}>
                    <DateBox type={field.type} ref={field.name} conf={field.conf} />
                  </InputsContainer>
                );
              case 'multiText':
                return (
                  <InputsContainer key={field.name+i}>
                    <TextArea ref={field.name} placeholder={field.placeholder} rows={field.rows} />
                  </InputsContainer>
                );
              default:
              }
            return null;
          })}
          <BottomButtonsContainer>
            <BottomButton
              submitChange
              ref={"saveChangeButton"}
              onClick={this.createItem}
            >
              {" "}
              Create{" "}
            </BottomButton>
            <BottomButton cancelChange onClick={()=>this.setState({showCreateIcon: true})}>
              {" "}
              Cancel{" "}
            </BottomButton>
          </BottomButtonsContainer>
        </SecFormContainer> }
      </SecondSubListContainer>
    )
  }
}