import React from 'react';
import { ResponsiveContainer, BarChart, Bar, Cell, ReferenceLine, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts';
import styled from 'styled-components';
import moment from 'moment';
import { theme } from '../../configurations/general-conf';

const ChartWrapper = styled.div`
  height: ${props => props.chartHeightPx ? props.chartHeightPx + 'px' : ''};
  width: ${props => props.chartWidthPercentage ? props.chartWidthPercentage + '%' : '100%'};
`;

export default function(props) {
  //console.log(props.data);
  let data=[];
  if(props.nameSpace.indexOf('stockEstimation')>-1){
    props.data.map( (obj, i) => {
      const dateToDisplay = new Date();
      dateToDisplay.setDate(dateToDisplay.getDate() + i);
      data.push({ name: moment(dateToDisplay).format('DD/MM'),  Quantity: obj});
      return null;
    });
  }else{
    data = props.data;
  }
  //console.log(data);
	return(
    <ChartWrapper chartHeightPx={props.chartHeightPx} chartWidthPercentage={props.chartWidthPercentage}>
      <ResponsiveContainer>
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3"/>
          <XAxis dataKey="name"/>
          <YAxis/>
          <Tooltip/>
          <ReferenceLine y={0} stroke='#000'/>
          <Bar dataKey="Quantity">
            {data.map((entry, i) => (
              <Cell key={i} fill={entry.Quantity < 200 ? theme.warningColor : theme.mainLighterColor }/>
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
}