import React from 'react';
import AppLogo from '../../resources/logo-app';
import styled from 'styled-components';
import TopMenus from '../views/top-menus';

const Header = styled.header`
  height: 94px;
	position: absolute;
	z-index: 2;
	width: 100%;
`;
const AppLogoWrapper = styled.div`
  width: 13.96875%;
	height: 94px;
	float: left;
	background: #fff;
`;

export default function(props) {
    return (
      <Header>
        <AppLogoWrapper>
          <AppLogo />
        </AppLogoWrapper>
        <TopMenus {...props} />
      </Header>
    );
}