import React from 'react';
import styled from 'styled-components';
//import { theme } from '../../configurations/general-conf';
import ComboBox from '../widgets/combo-box';
//import * as restful from '../../api/restful-api';

const SearchForm = styled.form`
  height: 60px;
  padding-left: 20px;
`;

const InputsContainer = styled.div`
  float: left;
  width: 300px;
  margin-top: 10px;
  margin-right: 25px;
`;

class ChartFilterContainer extends React.Component {
  componentDidMount (){
  }

  autoLoad = (name, value) => {
    //console.log(name + "/" + value);
    this.props.filters.slice().map( filter => {
      switch(filter.type) {
        case 'combo':
          if(filter.name===name){
            filter.currentValue = value ? value : '';
          }
          break;
        default:
          //return filter;
      }
      return filter;
    });
    //console.log(this.props.filters);
    this.props.loadData();
  }

  render () {
		return (
	  	<SearchForm>
				{this.props.filters.map((filter,i) => {
					switch(filter.type) {
            case 'combo':
              return (
                <InputsContainer key={filter.name+i}>
                  <ComboBox conf={filter.conf} ref={filter.name} name={filter.name} currentValue={filter.currentValue} autoLoad={this.autoLoad}/>
                </InputsContainer>
              );
						default:
					}
					return null;
        })}
			</SearchForm>
	  );
	}
}

export default ChartFilterContainer;