export const getWhname = (whUuid) => {
  switch (whUuid) {
    case "chesterhill":
      return "Sydney";
    case "dandenong":
      return "Melbourne";
    case "perth":
      return "Perth";
    case "brisbane":
      return "Brisbane";
    case "adelaide":
      return "Adelaide";
    case "CustContainers_uuid":
      return "Client Container";
    default:
      return whUuid;
  }
};