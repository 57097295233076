import React from 'react';
import MainTitle from '../views/main-title';
import ChartContainer from '../containers/chart-container';

export default function(props){
  return (
    <div>
      <MainTitle title={props.conf ? props.conf.title : 'Module Not Found'} />
      {props.conf && props.conf.charts && props.conf.charts.map( (chart, i) => {
        return(
          <ChartContainer key={i} conf={chart} />
        )
      })}
    </div>
  );
}