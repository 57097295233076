import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from '../../configurations/general-conf';

const SubMenuContainer = styled.div`
  position: relative;
`;

const SubMenuList = styled.ul`
  margin-top: 0;
	margin-bottom: 0;
	padding-top: 0;
	padding-bottom: 2px;
	padding-left: 0;
	list-style: none;
`;

const SubMenuListItem = styled.li`
  padding: 0;
	font-size: 14px;
	border-bottom: 1px solid ${theme.mainBgSeparatorColor};
  position: relative;
  
  & a {
    display: block;
    color: ${theme.mainDeepColor};
    line-height: 31px;
    padding-left: 20px;
    font-family: 'Univers-LT-Bold';
  }

  & svg {
    position: absolute;
    right: -6px;
    top: 0;
    display: none;
  }

  & a:hover svg, .subMenuActive svg {
    display: block;
  }
`;

export default function(props) {
	return (
    <SubMenuContainer> 
			<SubMenuList>
				{props.subMenus.map((subMenu) => {
					return (
						<SubMenuListItem key={subMenu.menuId}>
              <NavLink to={subMenu.menuLink} activeClassName="subMenuActive">
                {subMenu.menuName} <svg className='icon-SubMenuIndicator'> <use xlinkHref="#SubMenuIndicator" /> </svg>
              </NavLink>
						</SubMenuListItem>
					);
				})}
			</SubMenuList>
		</SubMenuContainer>
  );
}