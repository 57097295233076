import React from 'react';
import styled from 'styled-components';
import * as restful from '../../api/restful-api';
import { theme } from '../../configurations/general-conf';

const Title = styled.h1`
  margin-top: 30px;
	font-size: 28px;
  font-family: ${theme.midFont};
  color: ${theme.mainColor};
  margin-bottom: 0;
  padding-top: 2px;
  padding-bottom: 2px;
`;

const SubTitle = styled.p`
  font-size: 20px;
  color: ${theme.mainMenuHoverColor};
  margin-top: 2px;
  margin-bottom: 31px;
`;

export default function(props){
  return (
    <div>
      <Title>{props.title}</Title>
      <SubTitle>{restful.getCookie("companyName")}</SubTitle>
    </div>
  );
}