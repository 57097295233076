import React from 'react';
import tiLogo from './TrendInLogo.svg';
import styled from 'styled-components';

const Logo = styled.img`
  margin: 12px auto 0;
  display: block;
  max-width: 185px;
  max-height: 75px;
`;

const AppLogo = () => {
    return (
      <Logo alt="Logo" src={tiLogo} />
    );
}

export default AppLogo;