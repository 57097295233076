const initialState = {
	mainMenus: [],
}

const menuReducer = function(state = initialState, action) {

  switch(action.type) {
  	case 'get_mainMenus':
  		return Object.assign({}, state, { mainMenus: action.mainMenus });
  	default:
  }

  return state;

}

export default menuReducer;