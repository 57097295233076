import React from 'react';
import tiLogo from './TrendInLogo.svg';
import styled from 'styled-components';

const Logo = styled.img`
  padding: 30px;
  width: 400px;
`;

const TiLogo = () => {
    return (
      <Logo alt="Logo" src={tiLogo} />
    );
}

export default TiLogo;