import React from 'react';
import { connect } from 'react-redux';
import * as restful from '../../api/restful-api';
import HeaderLayout from './header-layout';
import { getMainMenus } from '../../api/menu-api';
import IconSystemConfiguration from '../../configurations/icon-system-configuration';
import SideBar from './sidebar-layout';
import Stage from './stage-layout';

class MainLayout extends React.Component {
  logOut = () => {
    restful.delCookie('auth_token');
    //console.log(restful.getCookie('auth_token'));
		restful.delCookie('userName');
		restful.delCookie('companyUuid');
		restful.delCookie('companyName');
		restful.delCookie('resturl');
    restful.delCookie('fileurl');
    this.props.history.push('/');
    //setTimeout(function(){window.location.href='/';},2000);
  }

  componentDidMount (){
    if(this.props.mainMenus.length===0) {
      getMainMenus();
    }
  }

  render (){
    restful.setAxiosDefaults();
    return (
      <div className="app">
        <IconSystemConfiguration />
        <HeaderLayout menus={this.props.mainMenus} logOut={this.logOut} />
        <SideBar menus={this.props.mainMenus} />
        <Stage menus={this.props.mainMenus} history={this.props.history} />
      </div>
    );
  }
}

const mapStateToProps = function(store) {
	return {
		mainMenus: store.menus.mainMenus
	};
};

export default connect(mapStateToProps, null, null, {pure:false})(MainLayout);