import React from 'react';
import store from '../../store';
import { addAsyncReducer } from '../../store';
import styled from 'styled-components';
import { theme } from '../../configurations/general-conf';
import Filters from './chart-filter-container';
import axios from 'axios';
import BarChartPN from '../widgets/bar-chart-pn';

const BlockWrapper = styled.div`
  float: left;
  background-color: ${props => props.bgColor ? theme[props.bgColor] : theme.mainBgColor};
  height: ${props => props.heightPx ? props.heightPx + 'px' : ''};
  width: ${props => props.widthPercentage ? props.widthPercentage + '%' : '100%'};
  margin-bottom: 32px;
  margin-right: 2%;
`;

const BlockTitle = styled.div`
  font-size: 16px;
  border-bottom: 1px solid ${theme.mainBgSeparatorColor};
  color: ${theme.mainDeepColor};
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  font-family: 'Univers-LT-Bold';
`;

class ChartContainer extends React.Component {
  constructor (props) {
    super(props);
    if(props.conf && !store.getState()[props.conf.nameSpace]){
      addAsyncReducer(store, props.conf);
    }
  }

  componentDidMount (){
    this.loadData();
  }

  loadData = async () => {
    //console.log("loading...");
    let endPoint = this.props.conf.endPoint;
    this.props.conf.filters.slice().map( filter => {
      switch(filter.type) {
        case 'combo':
          if(filter.currentValue){
            endPoint = endPoint.replace(':' + filter.name, filter.currentValue.value);
          }else if(filter.defaultValue){
            endPoint = endPoint.replace(':' + filter.name, filter.defaultValue);
          }
          break;
        default:
          //return filter;
      }
      return filter;
    });
    // console.log(endPoint);
    // axios.get(endPoint).then(res => {
    //   if(res.data.objs.length){
    //     store.dispatch({ type: 'get_' + this.props.conf.nameSpace, data: res.data });
    //   }
    // });
    const res = await axios.get(endPoint);
    if(res.data&&res.data.objs&&res.data.objs.length){
      store.dispatch({ type: 'get_' + this.props.conf.nameSpace, data: res.data });
    }
  }

  render (){
    const { conf } = this.props;
    return (
      <BlockWrapper heightPx={conf.heightPx} widthPercentage={conf.widthPercentage}>
        <BlockTitle>{conf.title}</BlockTitle>
        <Filters
          filters={ conf ? store.getState()[conf.nameSpace].filters : [] }
          nameSpace={ conf ? conf.nameSpace : null }
          loadData={ this.loadData }
        />
        <BarChartPN
          data={ conf ? store.getState()[conf.nameSpace].list : [] }
          nameSpace={ conf ? conf.nameSpace : null }
          chartWidthPercentage={ conf ? conf.chartWidthPercentage : null }
          chartHeightPx={ conf ? conf.chartHeightPx : null }
        />
      </BlockWrapper>
    );
  }
}

export default ChartContainer;