import React from 'react';
import styled from 'styled-components';
import { theme } from '../../configurations/general-conf';
import * as restful from '../../api/restful-api';
import moment from 'moment';

const SubListItemRelative = styled.li`
  float: left;
  width: ${ props => (101 - props.itemsPerRow) / props.itemsPerRow + '%' };
  margin-right: 1%;
  margin-bottom: 10px;

  &:nth-child(${props => props.itemsPerRow}n) {
    margin-right: 0;
  }
`;

const SubListImageContainer = styled.div`

  & img {
    margin: auto;
  }
`;

const SubListDateContainer = styled.div`
  text-align: center;
  font-size: 16px;
  line-height: 24px;
  color: ${theme.mainDarkColor};
`;

const SubListNumberContainerR = styled.div`
  text-align: center;
  font-size: 12px;
  line-height: 30px;
  color: ${theme.mainColor};

  & span:first-child {
    background: ${theme.mainBgColor};
    padding: 5px;
  }

  & span:last-child {
    background: ${theme.mainColor};
    color: #fff;
    padding: 5px;
  }
`;

const BtnContainer = styled.div`
  text-align: center;
  height: 20px;

  & button {
    border: none;
    padding: 0;
    background: none;
    cursor: pointer;
  }
`;

export default (props) => {
  const { subListCfg, subListItem, delItem } = props;
  return(
    <SubListItemRelative
      itemsPerRow={subListCfg.subListItemPerRow ? subListCfg.subListItemPerRow : 5 }
    >
      {subListCfg.subListFields.map( (field, i) => {
        switch (field.type) {
          case 'image':
            return (
              <SubListImageContainer key={i}>{ subListItem[field.valueKey] ? <img src={ restful.getCookie("fileurl") + subListItem[field.valueKey] } alt="Item" /> : ''}</SubListImageContainer>
            );
          case 'date':
            return (
              <SubListDateContainer key={i}><span>{moment(subListItem[field.valueKey]).format('DD/MM/YYYY')}</span></SubListDateContainer>
            );
          case 'number':
            let attLabelValue = '';
            //console.log(field);
            if(field.attLabelKey) {
              attLabelValue = subListItem;
              field.attLabelKey.split('.').map( item => {
                if (attLabelValue[item]) {
                  return attLabelValue = attLabelValue[item];
                } else {
                  return attLabelValue = '';
                }
              });
            }
            return (
              <SubListNumberContainerR key={i}><span>{ attLabelValue ? attLabelValue : field.label }</span><span>{subListItem[field.valueKey]}</span></SubListNumberContainerR>
            );
          case 'stock_num':
            let attLabelValue1 = '';
            //console.log(field);
            if(field.attLabelKey) {
              attLabelValue1 = subListItem;
              field.attLabelKey.split('.').map( item => {
                if (attLabelValue1[item]) {
                  return attLabelValue1 = attLabelValue1[item];
                } else {
                  return attLabelValue1 = '';
                }
              });
            }
            return (
              <SubListNumberContainerR key={i}><span>{ attLabelValue1 ? attLabelValue1 : field.label }</span><span>{subListItem[field.valueKey].toString() + " / " + subListItem[field.valueKey1].toString()}</span></SubListNumberContainerR>
            );
          case 'deletebtn':
              return (<BtnContainer key={i}>{ field.control && subListItem[field.control.key] === field.control.value && subListItem[field.control.key1] === field.control.value ? <button title='Delete' onClick={delItem.bind(null, subListItem[field.valueKey])}><svg className="icon-deletebin"><use xlinkHref="#deletebin" /></svg></button> : null }</BtnContainer>);
          default:
            return null;
        }
      })}
    </SubListItemRelative>
  );
}