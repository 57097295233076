import React from 'react';
import { NavLink } from 'react-router-dom';
import * as restful from '../../api/restful-api';
import { theme } from '../../configurations/general-conf';
import styled from 'styled-components';

const Container = styled.div`
  background-color: ${theme.mainDeepColor};
  width: 86.03125%;
  height: 94px;
  float: left;
`;

const MenuListContainer = styled.ul`
  height: 94px;
  padding: 0 0 0 0;
  margin: 0 0 0 0;
  list-style: none;
`;

const MeanListItem = styled.li`
  display: inline-block;
`;

const activeClassName = 'menuActive';

const StyledNavLink = styled(NavLink).attrs({activeClassName})`
  display: block;
	width: 95px;
  height: 94px;
  &.${activeClassName} {
    border-bottom: 6px solid ${theme.mainMenuHoverColor};
  }
  &:hover {
    border-bottom: 6px solid ${theme.mainMenuHoverColor};
  }
`;

const MenuItemBlock = styled.div`
  height: 94px;
  width: 95px;
  float: left;
  border-right: 1px solid ${theme.mainBrightestColor};
  text-align: center;
  font-size: 14px;
  color: white;
`;

const LogOutBlock = styled.div`
  color: ${theme.mainLightColor};
  float: right;
  margin-top: -55px;
  font-size: 14px;
  z-index: 1;
  margin-right: 3%;
`;

const LogOutButton = styled.button`
  color: white;
  z-index: 1;
  margin-right: 2px;
  margin-left: 2px;
  font-size: 14px;
  background: none;
  border: none;
  cursor: pointer;
`;

const SvgIcon = styled.svg`
  display: block;
  margin: 20px auto 5px auto;
`;

export default (props) => {
  //console.log(props.menus);
  return (
    <Container>
      <MenuListContainer>
        <MeanListItem>
            <StyledNavLink exact to="/" activeClassName="menuActive">
              <MenuItemBlock>
                  <SvgIcon className="icon-Home"><use xlinkHref="#Home" /></SvgIcon>
                  Home
                </MenuItemBlock>
            </StyledNavLink>
				</MeanListItem>
        {props.menus.map(mainMenu => {
					return (
						<MeanListItem key={mainMenu.menuId}>
							<StyledNavLink to={mainMenu.menuLink} activeClassName="menuActive">
                <MenuItemBlock>
                  <SvgIcon className={`icon-${mainMenu.menuName}`}><use xlinkHref={`#${mainMenu.menuName}`} /></SvgIcon>
                  {mainMenu.menuName}
                </MenuItemBlock>
							</StyledNavLink>
						</MeanListItem>
					);
				})}
      </MenuListContainer>
      <LogOutBlock>
        <span> {restful.getCookie("companyName")} </span>
        <span> | </span>
        <LogOutButton onClick={props.logOut}> Logout </LogOutButton>
      </LogOutBlock>
    </Container>
  );
}