import React from 'react';
import styled from 'styled-components';
import { theme } from '../../configurations/general-conf';
import * as restful from '../../api/restful-api';
import _ from 'lodash';

const CascadeContainer = styled.ul`
  width: 86.667%;
  margin: 0 auto;
`;

const MainListItem = styled.li`
  padding-bottom: 10px;
  padding-top: 48px;
  position: relative;
`;

const MainListItemTitle = styled.h4`
  background: ${theme.mainBgDeepColor};
  font-size: 18px;
  line-height: 38px;
  cursor: pointer;
  padding-left: 10px;
  margin-bottom: 10px;
  color: ${theme.mainColor};
  position: absolute;
  top: 0;
  width: 100%;

  & span {
    display: block;
    float: right;
    margin-right: 10px;
    font-family: ${theme.lightFont};
    color: ${theme.mainDarkColor};
  }
`;

const SubListContainer = styled.ul`
  display: ${props => props.show ? 'block' : 'none'};
`;

const SubListItem = styled.li`
  float: left;
  width: ${ props => (101 - props.itemsPerRow) / props.itemsPerRow + '%' };
  margin-right: 1%;
  margin-bottom: ${props => props.marginBottom + 'px'};
  position: relative;
  border: 1px solid ${theme.mainLighterColor};

  :before {
      content: "";
      display: block;
      padding-top: 100%;
  }

  &:nth-child(${props => props.itemsPerRow}n) {
    margin-right: 0;
  }
`;

const RoundCheckContainer = styled.div`
  width: 26px;
  height: 26px;
  background: #ddd;
  position: absolute;
  box-shadow: 0 1px 3px rgba(0,0,0,.5);
  top: 10px;
  left: 10px;
  z-index: 1;
`;
const CheckBoxInput = styled.input`
  visibility: hidden;
  &:checked+label {
    background: ${theme.mainLighterColor};
  }
`;
const RoundCheckLabel = styled.label`
  display: block;
  width: 20px;
  height: 20px;
  transition: all .5s ease;
  cursor: pointer;
  position: absolute;
  top: 3px;
  left: 3px;
  z-index: 1;
  background: #d3d3d3;
  box-shadow: inset 0 1px 3px rgba(0,0,0,.5);
`;

const GridImageContainer = styled.div`
  position: absolute;
  top: 0;

  & img {
    border-style: none;
    vertical-align: top;
    max-width: 100%;
    height: auto;
  }
`;

const GridName = styled.span`
  position: absolute;
  bottom: 0;
  background: #fff;
  padding: 10px;
  width: 100%;
  color: ${theme.mainColor};
`;

const GridCode = styled.span`
  position: absolute;
  top: 0;
  right: 0;
  background: #fff;
  padding: 5px;
  color: ${theme.mainDarkColor};
`;

const GridNumberMajor = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  background: ${theme.mainColor};
  padding: 5px;
  color: #fff;
`;

const InputContainer = styled.div`
  position: absolute;
  right: 0;
  margin-top: ${props => (props.stack * 28 +1) + 'px'};

  & label {
    color: ${theme.mainColor};
    position: relative;
  }

  & div {
    position: absolute;
    top: 12px;
    right: 5px;
    font-size: 12px;
  }
`;

const Input = styled.input`
  width: 75px;
  height: 26px;
  background: none;
  border: none;
  border-bottom: 1px solid ${theme.mainLighterColor};
  font-size: 14px;
  padding: 5px 0 5px 5px;
  color: ${theme.mainDarkColor};
  background: ${props => props.highlighted ? theme.mainLightColor : 'none'};
`;

export default class extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      checkedUuids: [],
      dataCollection: [],
      dataList: [],
      showMainUuids: [],
    }
  }

  changeSelection = (listItemId, e) => {
    if (e.target.checked) {
      const checkedUuids = this.state.checkedUuids.concat(listItemId);
      this.setState({ checkedUuids });
    } else {
      const checkedUuids = _.filter(this.state.checkedUuids, item => item !== listItemId);
      this.setState({ checkedUuids });
    }
  }
  
  toggleSelection = (listItemId, e) => {
    e.preventDefault();
    if(this.state.checkedUuids.indexOf(listItemId)>-1){
      const checkedUuids = this.state.checkedUuids.filter(item => item !== listItemId);
      this.setState({ checkedUuids });
    }else{
      const checkedUuids = [...this.state.checkedUuids, listItemId];
      this.setState({ checkedUuids });
    }
  }

  batchSelectionToggle = (selected, unSelected, e) => {
    e.preventDefault();
    if (selected.length<selected.length+unSelected.length) {
      // console.log(unSelected);
      const checkedUuids = [...this.state.checkedUuids, ...unSelected];
      this.setState({ checkedUuids });
    } else {
      // console.log(selected);
      let checkedUuids = this.state.checkedUuids;
      selected.map(obj => {
        return checkedUuids = checkedUuids.filter(item => item!==obj);
      })
      this.setState({ checkedUuids });
    }
  }

  toggleDisplay = (mainUuid, e) => {
    e.preventDefault();
    if (this.state.showMainUuids.indexOf(mainUuid)>-1) {
      const showMainUuids = _.filter(this.state.showMainUuids, item => item !== mainUuid);
      this.setState({ showMainUuids });
    } else {
      const showMainUuids = this.state.showMainUuids.concat(mainUuid);
      this.setState({ showMainUuids });
    }
  }

  handleInputChange = ( params, e) => {
    // console.log(e.target.value);
    const valueFromInput = params.reverseValue ? params.reverseValue : e.target.value;
    const valueToUse = params.unitSize ? parseFloat(Number(valueFromInput) * params.unitSize).toFixed(2) : Number(valueFromInput);
    // console.log(valueToUse);
    const currentPath = window.location.pathname;

    if (currentPath.indexOf('/new') > -1) {
      if(valueToUse>0){
        const objToAdd = _.filter(this.state.dataCollection, item => item[params.addKey] === params.addKeyValue);
        const obj = { ...objToAdd[0], [params.addKey]: params.addKeyValue, [params.valueKey]: valueToUse };
        const dataCollectionTemp = _.filter(this.state.dataCollection, item => item[params.addKey] !== params.addKeyValue);
        const dataCollection = dataCollectionTemp.concat(obj);
        // console.log(dataCollection);
        this.setState({dataCollection});
      }else{
        const dataCollection = _.filter(this.state.dataCollection, item => item[params.addKey] !== params.addKeyValue);
        this.setState({dataCollection});
      }
    }

    if (currentPath.indexOf('/edit') > -1) {
      if(valueToUse>0){
        if(params.updateKeyValue){
          const objToUpdate = _.filter(this.state.dataCollection, item => item[params.updateKey] === params.updateKeyValue);
          objToUpdate[0][params.valueKey] = valueToUse;
          objToUpdate[0].updateFlag = 2;
          const dataCollectionTemp = _.filter(this.state.dataCollection, item => item[params.updateKey] !== params.updateKeyValue);
          const dataCollection = dataCollectionTemp.concat(objToUpdate);
          // console.log(dataCollection);
          this.setState({dataCollection});
        }else{
          const objToAdd = { [params.addKey]: params.addKeyValue, [params.valueKey]: valueToUse, updateFlag: 1 };
          const dataCollectionTemp = _.filter(this.state.dataCollection, item => item[params.addKey] !== params.addKeyValue);
          const dataCollection = dataCollectionTemp.concat(objToAdd);
          this.setState({dataCollection});
        }
      }else{
        if(params.updateKeyValue){
          const objToRemove = _.filter(this.state.dataCollection, item => item[params.updateKey] === params.updateKeyValue);
          objToRemove[0][params.valueKey] = null;
          objToRemove[0].updateFlag = -1;
          const dataCollectionTemp = _.filter(this.state.dataCollection, item => item[params.updateKey] !== params.updateKeyValue);
          const dataCollection = dataCollectionTemp.concat(objToRemove);
          this.setState({dataCollection});
        }else{
          const dataCollection = _.filter(this.state.dataCollection, item => item[params.addKey] !== params.addKeyValue);
          this.setState({dataCollection});
        }
      }
    }

  }

  render () {
    const { mainCat, subListType, subListKey, subListFields, subListItemMargin, subListItemPerRow } = this.props.conf;
    // console.log(this.state.dataList);
    return(
      <CascadeContainer>
        {this.state.dataList.map( mainListItem => {
          let selected = [];
          let unSelected = [];
          const unitSizeCat = mainListItem.unitSymbol === "m2" ? mainListItem.unitSize : null;
          // console.log(unitSizeCat);
          return (
            <MainListItem key={mainListItem[mainCat.valueKey]}>
              <SubListContainer show={this.state.showMainUuids.indexOf(mainListItem[mainCat.valueKey])>-1 ? true : false} className="clearfix">
                {subListType==='grid' ? mainListItem[subListKey] && mainListItem[subListKey].map( subListItem => {
                  
                  const obj = _.filter(this.state.dataCollection, item => item[subListFields[0].valueKey] === subListItem[subListFields[0].valueKey]);
                  
                  if(this.state.checkedUuids.indexOf(subListItem[subListFields[0].valueKey])>-1){
                    selected.push(subListItem[subListFields[0].valueKey]);
                  }else if(obj.length){
                    selected.push(subListItem[subListFields[0].valueKey]);
                  }else{
                    unSelected.push(subListItem[subListFields[0].valueKey]);
                  }

                  return (
                    <SubListItem
                      key={subListItem[subListFields[0].valueKey]}
                      marginBottom={subListItemMargin ? subListItemMargin : 10}
                      itemsPerRow={subListItemPerRow ? subListItemPerRow : 5 }
                      onClick={this.toggleSelection.bind(null, subListItem[subListFields[0].valueKey])}
                    >
                      {subListFields.map( (field, i) => {
                        let unitPrice = null;
                        const unitSize = subListItem.unitSymbol === "m2" ? subListItem.unitSize : null;
                        if(field.valueKey==="retailPrice"||field.valueKey==="wholesalePrice"){
                          unitPrice = (unitSize || unitSizeCat) && obj[0]?.[field.valueKey] ? parseFloat(obj[0]?.[field.valueKey] / (unitSize || unitSizeCat)).toFixed(2) : null;
                        }
                        if(subListItem.unitSymbol && subListItem.unitSymbol!=="m2"){
                          unitPrice=null;
                        }
                        const dspByM2 = (field.valueKey==="retailPrice"||field.valueKey==="wholesalePrice") && subListItem.unitSymbol === "m2";

                        switch (field.type) {
                          case 'checkbox':
                            return (
                              <RoundCheckContainer key={i} hidden={ field.hidden ? field.hidden : false }>
                                <CheckBoxInput
                                  type="checkbox"
                                  id={`rounded${subListItem[field.valueKey]}`}
                                  checked={this.state.checkedUuids.indexOf(subListItem[field.valueKey])>-1}
                                  onChange={this.changeSelection.bind(null, subListItem[field.valueKey])}
                                  onClick={function(event){event.stopPropagation();}}
                                />
                                <RoundCheckLabel id={`dummy${subListItem[field.valueKey]}`} htmlFor={`rounded${subListItem[field.valueKey]}`} onClick={function(event){event.stopPropagation();}} />
                              </RoundCheckContainer>
                            );
                          case 'sCheckbox':
                            return (
                              <RoundCheckContainer key={i} hidden={ field.hidden ? field.hidden : false }>
                                <CheckBoxInput
                                  type="checkbox"
                                  id={`rounded${subListItem[field.valueKey]}`}
                                  checked={obj.length && obj[0][field.valueKey1] ? true : false}
                                  onChange={this.handleInputChange.bind(null, {
                                    addKey: field.addKey,
                                    addKeyValue: subListItem[field.addKey],
                                    updateKey: field.updateKey,
                                    updateKeyValue: obj.length ? obj[0][field.updateKey] : null,
                                    valueKey: field.valueKey1,
                                    unitSize: dspByM2 ? (unitSize || unitSizeCat) : null,
                                    reverseValue: obj.length && obj[0][field.valueKey1] ? 0 : 1,
                                  })}
                                  onClick={function(event){event.stopPropagation();}}
                                />
                                <RoundCheckLabel id={`dummy${subListItem[field.valueKey]}`} htmlFor={`rounded${subListItem[field.valueKey]}`} onClick={function(event){event.stopPropagation();}} />
                              </RoundCheckContainer>
                            );
                          case 'image':
                            return (
                              <GridImageContainer onClick={field.function==='toggle' ? this.handleInputChange.bind(null, {
                                addKey: field.addKey,
                                addKeyValue: subListItem[field.addKey],
                                updateKey: field.updateKey,
                                updateKeyValue: obj.length ? obj[0][field.updateKey] : null,
                                valueKey: field.valueKey1,
                                unitSize: dspByM2 ? (unitSize || unitSizeCat) : null,
                                reverseValue: obj.length && obj[0][field.valueKey1] ? 0 : 1,
                              }): null } key={i}>{ subListItem[field.valueKey] ? <img src={ restful.getCookie("fileurl") + subListItem[field.valueKey] } alt="Item" /> : ''}</GridImageContainer>
                            );
                          case 'name':
                            return (
                              <GridName key={i}>{ subListItem[field.valueKey] }</GridName>
                            );
                          case 'majorNumber':
                            return (
                              <GridNumberMajor key={i}><span>{subListItem[field.valueKey]}</span><span>{field.label}</span></GridNumberMajor>
                            );
                          case 'code':
                            return (
                              <GridCode key={i}>{ subListItem[field.valueKey] }</GridCode>
                            );
                          case 'input':
                            // console.log(unitPrice);
                            return (
                              <InputContainer key={i} stack={field.stack || 0}>
                                <label>{dspByM2 ? field.label + "/m2" : field.label}{unitPrice && <div>{obj[0]?.[field.valueKey]}/pack</div>}</label>
                                <Input
                                  highlighted = { obj.length && obj[0][field.valueKey] ? true : false }
                                  type={field.dataType}
                                  step={field.step || 1}
                                  defaultValue = { obj.length ? (unitPrice ? unitPrice : obj[0][field.valueKey]) : null }
                                  onBlur={this.handleInputChange.bind(null, {
                                    addKey: field.addKey,
                                    addKeyValue: subListItem[field.addKey],
                                    updateKey: field.updateKey,
                                    updateKeyValue: obj.length ? obj[0][field.updateKey] : null,
                                    valueKey: field.valueKey,
                                    unitSize: dspByM2 ? (unitSize || unitSizeCat) : null,
                                  })}/>
                                
                              </InputContainer>
                            );
                          default:
                            let value = subListItem;
                            field.valueKey.split('.').map( item => {
                              return value = value[item];
                            });
                            return(<span key={i}>{value}</span>);
                        }
                      })}
                    </SubListItem>
                  );
                }) : null}
              </SubListContainer>
              <MainListItemTitle
                onClick={this.toggleDisplay.bind(null, mainListItem[mainCat.valueKey])}
                onDoubleClick={this.batchSelectionToggle.bind(null, selected, unSelected)}
                title={`click to ${this.state.showMainUuids.indexOf(mainListItem[mainCat.valueKey])>-1 ? 'hide' : 'show'} subitems`}
              >
                {mainListItem[mainCat.lableKey]}<span>{selected.length}/{mainListItem[subListKey] ? mainListItem[subListKey].length : 0} Selected</span>
              </MainListItemTitle>
            </MainListItem>
          );
        })}
      </CascadeContainer>
    );
  }
}