import React from 'react';
import MainTitle from '../views/main-title';
import axios from 'axios';
import styled from 'styled-components';
import { theme } from '../../configurations/general-conf';
import CascadeList from '../widgets/cascade-list';
import _ from 'lodash';


const BottomButtonsContainer = styled.div`
  float: right;
  margin-top: 20px;
  margin-bottom: 10px;
`;

const BottomButton = styled.button`
  height: 38px;
  width: 120px;
  border: none;
  padding: 0;
  margin-left: 10px;
  font-size: 12px;
  color: #fff;
  background-color: ${
    props => props.submitChange ? theme.mainLighterColor : props.cancelChange ? theme.failureColor : theme.mainDarkColor
  };

  &:hover {
    background-color: ${
      props => props.submitChange ? theme.mainLightColor : props.cancelChange ? theme.failureLightColor : theme.mainDarkColorHover
    }
  }
`;

const SearchForm = styled.form`
  background-color: ${theme.mainBgColor};
  height: 110px;
  position: relative;
  margin-top: 15px;
  margin-bottom: 15px;
  padding-top: 2px;
  padding-bottom: 2px;
`;

const BlockTitle = styled.div`
  height: 150px;
  box-sizing: border-box;
  float: left;
  padding-top: 10px;

  & label {
    font-size: 14px;
    font-family: Univers-LT-Bold;
    color: ${theme.mainDeepColor};
    margin-right: 50px;
    margin-left: 20px;
    margin-top: 7px;
    display: inline-block;
  }
`;

const ButtonsContainer = styled.div`
  height: 168px;
  width: 175px;
  float: right;
  margin-right: 18px;
`;

const SearchButton = styled.button`
  background-color: ${theme.mainColor};
  color: ${theme.mainBrightestColor};
  margin-top: 10px;
  width: 175px;
  height: 38px;
  border: none;
  padding: 0;

  &:hover {
    background-color: ${theme.mainLighterColor};
    transition: background-color .3s;
  }
`;

const ClearButton = styled.button`
  background-color: ${theme.mainBgDeepColor};
  margin-top: 10px;
  width: 175px;
  height: 38px;
  border: none;
  padding: 0;

  &:hover {
    background-color: ${theme.mainBgDeeperColor};
    transition: background-color .3s;
  }
`;

const InputsContainer = styled.div`
  float: left;
  width: 180px;
  margin-top: 10px;
  margin-right: 25px;
`;

const Input = styled.input`
  font-size: 14px;
  max-width: 100%;
  height: 38px;
  box-sizing: border-box;
  border: 1px solid ${theme.mainBgColor};
  padding: 0 0 0 10px; 

  &:hover {
    border-color: ${theme.mainLighterColor};
  }
`;

export default class extends React.Component {

  constructor (props) {
    super(props);
    this.state = {
      originalData: [],
    }
  }

  async componentDidMount () {
    const { endPoint, name, endPointSub } = this.props.conf;
    const { listItemId } = this.props.match.params;
    const endPointGet = endPoint.get ? endPoint.get.replace(':uuid', listItemId) : endPoint.replace(':uuid', listItemId);
    
    const res = await axios.get(endPointGet);
    if (res.data.objs && name==='dispatch') {
      const findBarcodes = _.filter(res.data.objs, item => item.barcodes);
      // console.log(findBarcodes);
      if (findBarcodes.length>0) {
        this.refs["saveChangeButton"].disabled = true;
        this.refs["saveChangeButton"].hidden = true;
        window.alert('There is a technical issue with this order. Please contact the tech support for futher assistance.');
      }
      this.refs[name].setState({ dataList: res.data.objs });
    };
    if (res.data.objs && name==='loadContainer') {
      const containerPlanItems = [];
      res.data.objs[0].containerItems.forEach(item => {
        item.notes && item.notes.split(',').forEach(note => {
          containerPlanItems.push({
            productionPlansUuid: note.split(':')[0],
            loadedQty: Number(note.split(':')[1]),
          });
        });
      });
      // console.log(containerPlanItems);
      const endPointSubGet = endPointSub.replace(':supplierUuid', res.data.objs[0]?.supplierUuid);
      const resSub = await axios.get(endPointSubGet);
      let dataList = [];
      resSub.data.objs?.length>0 && resSub.data.objs.forEach(item => {
        if(item.status===2 && item.completedQty>item.shippedQty){
          const newItem = {
            productUuid: item.productUuid,
            productCode: item.productCode,
            productName: item.productName,
            productImageUrl: item.info5,
            productionPlans: [
              {
                productionPlansUuid: item.productionPlansUuid,
                completedDate: item.completedDate,
                completedQty: item.completedQty,
                loadingQty: item.loadingQty,
                shippedQty: item.shippedQty,
                barcode: item.barcode,
                loadedQty: containerPlanItems.find(i => i.productionPlansUuid===item.productionPlansUuid)?.loadedQty,
              }
            ]
          };
          const index = dataList.findIndex(i => i.productUuid===newItem.productUuid);
          index===-1 ? dataList.push(newItem) : dataList[index].productionPlans.push(newItem.productionPlans[0]);
        }
      });
      // console.log(dataList);
      this.setState({ originalData: dataList });
      this.refs[name].setState({ dataList: dataList });
    }
  }

  cancelChange = () => {
    const { route } = this.props.conf;
    const backPath = this.props.match.path.replace(route + '/:listItemId', '');
    this.props.history.push(backPath);
  }

  saveChange = async () => {
    const { endPoint, name, conf, route } = this.props.conf;
    const { listItemId } = this.props.match.params;
    if (name==='dispatch') {
      const { dataToSubmit, dataList } = this.refs[name].state;
      const notEqual = _.filter(dataList, item => item.subTotal !== item[conf.subTotalMatchKey]);
      if(notEqual.length===0){
        const endPointPut = endPoint.put ? endPoint.put.replace(':uuid', listItemId) : endPoint.replace(':uuid', listItemId);
        // console.log(dataToSubmit);
        this.refs["saveChangeButton"].disabled = true;
        const res = await axios.put(endPointPut, dataToSubmit);
        if (res.data.result === 1) {
          const backPath = this.props.match.path.replace(route + '/:listItemId', '');
          this.props.history.push(backPath);
        } else {
          this.refs["saveChangeButton"].disabled = false;
          window.alert(res.data.msg);
        }
      }
    }
    if(name==='loadContainer'){
      const { dataToSubmit } = this.refs[name].state;
      // console.log(dataToSubmit);
      const endPointPut = endPoint.replace(':uuid', listItemId) + '/load';
      this.refs["saveChangeButton"].disabled = true;
      const res = await axios.put(endPointPut, dataToSubmit);
      if (res.data.result === 1) {
        const backPath = this.props.match.path.replace(route + '/:listItemId', '');
        this.props.history.push(backPath);
      } else {
        this.refs["saveChangeButton"].disabled = false;
        window.alert(res.data.msg);
      }
    }
  }

  handleSumbit = (e) => {
    e.preventDefault();
    const newList = [];
    const originalList = _.cloneDeep(this.state.originalData);
    originalList.forEach(item => {
      if(item.productCode.toLowerCase().indexOf(this.refs.productQuery.value.trim().toLowerCase())>-1 || item.productName.toLowerCase().indexOf(this.refs.productQuery.value.trim().toLowerCase())>-1){
        item.productionPlans = item.productionPlans.filter(plan => plan.barcode.toLowerCase().indexOf(this.refs.barcodeQuery.value.trim().toLowerCase())>-1);
        item.productionPlans.length>0 && newList.push(item);
      }
    });
    this.refs.loadContainer.setState({ dataList: newList });
  }

  resetFilters = (e) => {
    e.preventDefault();
    this.refs.productQuery.value = '';
    this.refs.barcodeQuery.value = '';
    this.handleSumbit({preventDefault: () => {}});
  }

  render () {
    const { title, name, conf } = this.props.conf;
    return (
      <React.Fragment>
        <MainTitle title={title} />

        { name==='loadContainer' ?
          <SearchForm onSubmit={this.handleSumbit}>
            <BlockTitle> <label> Filter: </label> </BlockTitle>
            <ButtonsContainer>
              <SearchButton type="submit"> Search </SearchButton>
              <ClearButton type="reset" onClick={this.resetFilters}> Reset </ClearButton>
            </ButtonsContainer>
            <InputsContainer>
              <Input type="text" ref="productQuery" placeholder="Product Code / Name" />
            </InputsContainer>
            <InputsContainer>
              <Input type="text" ref="barcodeQuery" placeholder="Barcode" />
            </InputsContainer>
          </SearchForm>
        : null }

        { name==='dispatch' ? <CascadeList ref={name} conf={conf} /> : null }
        { name==='loadContainer' ? <CascadeList ref={name} conf={conf} /> : null }

        <BottomButtonsContainer>
          <BottomButton submitChange ref={"saveChangeButton"} onClick={this.saveChange}> Submit </BottomButton>
          <BottomButton cancelChange onClick={this.cancelChange}> Cancel </BottomButton>
        </BottomButtonsContainer>
      </React.Fragment>
    );
  }
}