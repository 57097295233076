import React from 'react';
import DatePicker from 'react-datepicker';
import '../../resources/react-datepicker.css';
import styled from 'styled-components';
import { theme } from '../../configurations/general-conf';

const DatePickerWrapper = styled.div`
  float: left;
  width: 180px;
  margin-top: 10px;
  margin-right: 25px;

  & input {
    font-size: 14px;
    max-width: 100%;
    height: 38px;
    box-sizing: border-box;
    border: 1px solid ${theme.mainBgColor};
    padding: 0 0 0 10px;
  }

  & .react-datepicker__close-icon::after {
    background-color: ${theme.mainColor};
    border-radius: 0;
  }

  & .react-datepicker {
    border-radius: 0;
  }

  & .react-datepicker__day--keyboard-selected, .react-datepicker__month-text--keyboard-selected, .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range, .react-datepicker__month-text--selected, .react-datepicker__month-text--in-selecting-range, .react-datepicker__month-text--in-range {
    background-color: ${theme.mainColor};
    border-radius: 0;
  }

  & .react-datepicker__day--keyboard-selected:hover, .react-datepicker__month-text--keyboard-selected:hover, .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover, .react-datepicker__month-text--selected:hover, .react-datepicker__month-text--in-selecting-range:hover, .react-datepicker__month-text--in-range:hover {
    background-color: ${theme.mainLighterColor};
    border-radius: 0;
  }

  & .react-datepicker__day:hover, .react-datepicker__month-text:hover {
    border-radius: 0;
  }
`;

export default class extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      startDate: this.props.startDate ? this.props.startDate : null,
			endDate: this.props.endDate ? this.props.endDate : null
    }
  }

  handleChangeStart = (date) => {
		this.setState({ startDate: date });
	}

	handleChangeEnd = (date) => {
		this.setState({ endDate: date });
	}

  render () {
    const conf = this.props.conf ? this.props.conf : {};
		return (
      <React.Fragment>
        <DatePickerWrapper>
          <DatePicker
            placeholderText={conf.placeholderStart ? conf.placeholderStart : 'Select Start Date'}
            dateFormat={conf.dateFormat ? conf.dateFormat : "dd/MM/yyyy"}
            selectsStart
            selected={this.state.startDate}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onChange={this.handleChangeStart}
            isClearable={conf.isClearable ? conf.isClearable : true}
          />
        </DatePickerWrapper>
        <DatePickerWrapper>
          <DatePicker
            placeholderText={conf.placeholderEnd ? conf.placeholderEnd : 'Select End Date'}
            dateFormat={conf.dateFormat ? conf.dateFormat : "dd/MM/yyyy"}
            selectsEnd
            selected={this.state.endDate}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            onChange={this.handleChangeEnd}
            isClearable={conf.isClearable ? conf.isClearable : true}
          />
        </DatePickerWrapper>
      </React.Fragment>
		);
	}
}